import { produce } from "immer";
import {
  SET_FILES,
  FILE_UPLOAD_COMPLETED,
  FILES_S3,
  FILES_S3_SIGNED_UPLOADED,
  SET_CLEAR_FILES,
  REMOVE_FILE_S3,
} from "../actions/Experiments";
import moment from "moment";
import { uploadSpeed } from "../utils/basic";

const initialState = {
  files: {},
  fileUploadCompleted: false,
  filesS3: [],
};

const fileReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILES: {
      draft.files = action.files;
      return draft;
    }

    case FILE_UPLOAD_COMPLETED: {
      draft.fileUploadCompleted = payload;
      return draft;
    }

    case FILES_S3: {
      draft.filesS3.push(payload);
      return draft;
    }

    case REMOVE_FILE_S3: {
      draft.filesS3.forEach((file, index) => {
        if (
          file.fileId == action.file.fileId ||
          file.name == action.file.name
        ) {
          draft.filesS3.splice(index, 1);
        }
      });
      return draft;
    }

    case FILES_S3_SIGNED_UPLOADED: {
      var files = payload;
      const fileToUpdate = draft.filesS3.find(
        (f) => f.fileId === files[action.thisFileIndex].fileId
      );
      if (fileToUpdate) {
        fileToUpdate.parts[action.thisPartIndex].uploaded =
          action.progressEvent.loaded;

        const totalUploaded = fileToUpdate.parts.reduce((acc, part) => {
          return acc + part.uploaded;
        }, 0);

        fileToUpdate.percent =
          action.progressEvent.loaded === action.progressEvent.total
            ? 100
            : Math.ceil((totalUploaded / fileToUpdate.size) * 100);

        fileToUpdate.speed = uploadSpeed(
          moment(action.progressEvent.timestamp).unix(),
          fileToUpdate.startedAt,
          fileToUpdate.size,
          totalUploaded
        );
      }

      return draft;
    }

    case SET_CLEAR_FILES: {
      (draft.files = {}),
        (draft.filesS3 = []),
        (draft.fileUploadCompleted = false);
      return draft;
    }

    default: {
      return draft;
    }
  }
});

export default fileReducer;
