import { Route, Switch, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { hot } from "react-hot-loader";
import AppWrapper from "./common/AppWrapper/index.jsx";
import Login from "./Login/index.jsx";
import PropTypes from "prop-types";
import "scss/App.scss";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import NewUser from "./NewUser/index.js"
import Home from "./Home/index.jsx";
import ExperimentAdd from "./Experiment/Add/index.jsx";
import ExperimentDetail from "./Experiment/Details/index.jsx";
import TagsList from "./Tags/List/index.jsx";
import { seqUserGroup } from "components/common/Constants/appConstants";
import Toaster from "components/common/Toaster.jsx";

const theme = createTheme();

function App() {


  const { instance } = useMsal();
  const history = useHistory();
  const [showToaster, setShowToaster] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const handleRedirect = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (response?.idToken && isMounted) {
          const userGroup = response?.idTokenClaims?.groupsCommaDelimited?.split(",") || [];
          const seqGroup = seqUserGroup;
          if (userGroup.includes(seqGroup)) {
            history.push("/home");
          } else {
            instance.logoutRedirect({
              state: "error=Invalid user group. Please contact administrator",
              idTokenHint: response?.idToken
            }).then(() => {
              // Replace the current history entry with the login page
              history.replace({
                pathname: "/",
                search: `?state=${encodeURIComponent("error=Invalid user group. Please contact administrator")}`,
              });
              setShowToaster(true);
            })

          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleRedirect();
    return () => {
      isMounted = false;
    };
  }, [instance, showToaster]);



  return (
    <div id="atacApp">
      {showToaster && (
        <Toaster
          text={<FormattedMessage id="group.error" defaultMessage={"Invalid user group. Please contact administrator"} />}
          autoHideDuration={55_000}
          type="error"
          message="You are not a member of the required user group"
          key={crypto.randomUUID()}
        />
      )}
      <AppWrapper>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Switch>
              <UnauthenticatedTemplate>
                <Route exact path="/newuser" component={NewUser} />
                {/* <Route exact path="/">
                  <Login />
                </Route> */}
                <Route exact path={["/", '/login']} component={Login} />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>




                <Route path="/home"
                  component={Home}
                />
                <Route path="/create-experiment/pipeline-selection"
                  component={ExperimentAdd}
                />
                <Route path="/create-experiment/file-selection"
                  component={ExperimentAdd}
                />
                <Route path="/create-experiment/configure-pipeline"
                  component={ExperimentAdd}
                />
                <Route
                  exact={true}
                  path="/experiment-detail"
                  component={ExperimentDetail}
                />
                <Route
                  exact={true}
                  path="/tag/list"
                  component={TagsList}
                />

              </AuthenticatedTemplate>
            </Switch>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppWrapper>
    </div>
  );
}

App.propTypes = {
  children: PropTypes.node,
};
export default hot(module)(App);
