import React, { useReducer, useEffect, useState } from "react";
import "scss/App.scss";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "utils/basic";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "underscore";
import moment from "moment";
import "./theme.css";
import * as config from "../../../app.config";
import axios from "axios";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from "react-intl";
import * as experimentActions from "actions/Experiments";
import { connect, useDispatch } from "react-redux";
import Toaster from "components/common/Toaster.jsx";
import { bindActionCreators, compose } from "redux";
import { signUrl } from "components/api/signUrl";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import FileIcon from "images/icons-table-generic.svg";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import useDeleteExistingFile from "components/Hooks/SampleFiles/useDeleteExistingFile";
import uuid from "react-uuid";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import * as ExperimentAddActions from "actions/ExperimentAdd";
import { getAccessToken } from "actions/Azure";
import { useIsMutating } from "react-query";

const SLICE_SIZE = 5000 * 1024 * 1024; // 5gb in bytes
const initialState = {
  fileInfo: [],
  loading: false,
  error: "",
  fileExists: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
        fileInfo: [],
      };

    case "ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

function UploadFile({ actions, filesS3 }) {
  const isMutatingSaveExp = useIsMutating(['saveExp']);
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    accept: ".fastq.gz, .fq.gz",
    noClick: true,
  });
  const dispatchAction = useDispatch();
  const accessToken = getAccessToken();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const deleteExistingFile = useDeleteExistingFile();
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const [errorFiles, setErrorFiles] = useState([]);
  const {
    experimentId,
    fileNameError,
    disable,
    selected,
    allFiles,
    fileExists,
    pipelineId,
  } = experimentState;

  const setDisable = (payload) => {
    dispatchEvent({ type: ExperimentAddActions.SET_DISABLE, payload });
  };
  const setDisableUpload = (payload) => {
    dispatchEvent({ type: ExperimentAddActions.SET_DISABLE_UPLOAD, payload });
  };
  const setAllFiles = (payload) => {
    dispatchEvent({ type: ExperimentAddActions.SET_ALL_FILES, payload });
  };

  //TODO: Here based off the pipeline chosen while creating the Experiment , retrieve the region

  useEffect(() => {
    dispatchEvent({
      type: ExperimentAddActions.SET_FILE_NAME_ERROR,
      payload: false,
    });

    //TODO: Come up with better approach to handle this - two for loops introduces O(n2) complexity
    for (let i = 0, fileLength = filesS3.length; i < fileLength; i++) {
      for (
        let j = 0, duplicateFileList = acceptedFiles.length;
        j < duplicateFileList;
        j++
      ) {
        if (filesS3[i].name !== acceptedFiles[j].name) {
          continue;
        }
        acceptedFiles.splice(j, 1);
        duplicateFileList = acceptedFiles.length;
        //  dispatch({ type: "FILE_EXISTS", payload: true });
        dispatchEvent({
          type: ExperimentAddActions.SET_FILE_EXISTS,
          payload: true,
        });
      }
    }
    const fileNames = _.pluck(acceptedFiles, "name");
    let getStrArr = [];
    let newStrArr = [];
    let check;
    fileNames.forEach(function (item, index, arr) {
      getStrArr = item.split("_");
      newStrArr = getStrArr.slice(-4);
      check = newStrArr[0].startsWith("S");
      if (!check) {
        arr.length = index + 1;
      }
    });
    const regex = /_S.*_L?([0-9]{3})_R(1|2)_001\.(fastq|fq)\.gz$/i;
    let fileNameCheck = regex.test(fileNames) && check;
    if (fileNames.length > 0) {
      if (fileNameCheck) {
        dispatchAction({ type: experimentActions.SET_FILES, files: fileNames });
        // dispatch({ type: "LOADING", payload: true });
        acceptedFiles.forEach((file) => {
          handleFileUpload(file);
        });
      } else {
        dispatchEvent({
          type: ExperimentAddActions.SET_FILE_NAME_ERROR,
          payload: true,
        });
      }
    }

    if (fileNameError) {
      removeAll();
    }

    return () => {
      dispatchEvent({
        type: ExperimentAddActions.SET_FILE_EXISTS,
        payload: false,
      });
      dispatchEvent({
        type: ExperimentAddActions.SET_FILE_NAME_ERROR,
        payload: false,
      });
    };
  }, [acceptedFiles, isAuthenticated, fileNameError]);

  const removeAll = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
  }

  function handleFileUpload(f) {
    let iterator = 0;
    const signedUrls = [];
    const file = {
      fileId: uuid(),
      size: f.size,
      name: f.name,
      timeLeft: "",
      percent: 0,
      speed: "",
      startedAt: moment().unix(),
      parts: [],
      errStatus: false
    };

    const fileSize = f.size - 1;
    for (let i = 0; i < fileSize; i += SLICE_SIZE) {
      const start = i;
      const partName = `${f.name}.${iterator}.part`;
      const endsAt = SLICE_SIZE + start;
      const blob = f.slice(start, endsAt);
      file.parts.push({ partName: partName, uploaded: false });
      dispatchAction({
        type: experimentActions.FILES_S3,
        experimentId,
        payload: file,
      });

      signedUrls.push(signUrl(partName, blob, experimentId, pipelineId));
      iterator++;
    }
    axios.all(signedUrls)
      .then((signedUrlsData) => {
        setDisableUpload(true);
        const fileInfo = [];
        fileInfo.push(file);
        const promises = signedUrlsData.map((signedData) => {
          return new Promise((resolve, reject) => {
            if (signedData.status === 409) {
              const error = new Error(signedData.data);
              const errorMessage = signedData.data;
              // dispatch error here
              dispatchEvent({
                type: ExperimentAddActions.SET_FILE_EXISTS,
                payload: true,
              });
              setErrorFiles((prev) => [...prev, errorMessage.match(/(\S+\.\S+)/)[1]]);
              // style this offending file to RED color in render                        
              reject(error);
            } else {
              resolve(handlePartUpload(
                signedData.partName,
                signedData,
                signedData.blob,
                file,
                experimentId
              ));
            }
          });
        });
        return Promise.all(promises);
      })
      .then(() => {
        // All parts were uploaded successfully
      })
      .catch((error) => {
        // At least one part failed to upload
        console.error("Error uploading part:", error);
        // Display the error message to the user
      });



    // axios.all(signedUrls).then((signedUrlsData) => {
    //   console.log("signedUrlsData", signedUrlsData);
    //   setDisableUpload(true);
    //   const fileInfo = [];
    //   fileInfo.push(file);
    //   signedUrlsData.forEach((signedData) => {
    //     console.log("signedData", signedData);
    //     return handlePartUpload(
    //       signedData.partName,
    //       signedData,
    //       signedData.blob,
    //       file,
    //       experimentId
    //     );
    //   });
    // });
  }

  const handlePartUpload = (partName, data, blob, fileInfo, experimentId) => {
    const frmData = new FormData();
    frmData.set("acl", data.upload_fields.fields.acl);
    frmData.set("key", data.upload_fields.fields.key);
    frmData.set("policy", data.upload_fields.fields.policy);
    frmData.set(
      "x-amz-algorithm",
      data.upload_fields.fields["x-amz-algorithm"]
    );
    frmData.set(
      "x-amz-credential",
      data.upload_fields.fields["x-amz-credential"]
    );
    frmData.set("x-amz-date", data.upload_fields.fields["x-amz-date"]);
    frmData.set(
      "x-amz-security-token",
      data.upload_fields.fields["x-amz-security-token"]
    );
    frmData.set(
      "x-amz-signature",
      data.upload_fields.fields["x-amz-signature"]
    );
    frmData.set("file", blob, partName);

    axios({
      method: "post",
      url: config.ENDPOINTS.s3Bucket,
      data: frmData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: _.debounce((progressEvent) => {
        let files = [];
        files.push(fileInfo);
        let mainFile = partName.split(".");
        mainFile = mainFile.slice(0, -2).join(".");
        let thisFileIndex = _.findIndex(files, { name: mainFile });
        let thisPartIndex = _.findIndex(files[thisFileIndex].parts, {
          partName: partName,
        });

        actions.filesS3SignedUploaded(
          experimentId,
          files,
          thisFileIndex,
          thisPartIndex,
          progressEvent
        );
      }, 200),
    }).catch((err) => {
      return (
        err?.response && err.response.status === 401 && dispatch(instance.logoutRedirect({
          idTokenHint: accessToken
        }))
      );
    });
  };

  const remove = (file) => {
    acceptedFiles.splice(file, 1);
    const { name, fileId } = file;
    const str = `${experimentId}**${name}**${fileId}`;
    deleteExistingFile.mutate(str, {
      onSuccess: () => {
        setDisable(false);
        setDisableUpload(!acceptedFiles.length);
        dispatchEvent({
          type: ExperimentAddActions.SET_FILTER_UPLOADED_FILES,
          payload: fileId,
        });
        dispatchEvent({
          type: ExperimentAddActions.SET_FILTER_SELECTED,
          payload: fileId,
        });
        dispatchEvent({
          type: ExperimentAddActions.SET_FILTER_ALL_FILES,
          payload: fileId,
        });
        dispatchEvent({
          type: ExperimentAddActions.SET_FILTER_ALL_FILES_BY_NAME,
          payload: name,
        });
      },
    });
    dispatchAction({ type: experimentActions.REMOVE_FILE_S3, file });
  };

  useEffect(() => {
    if (filesS3) {
      const allFilesTitles = allFiles.map((f) => f.name);
      const allFilsList = [...allFiles];

      filesS3.forEach((file) => {
        const { name } = file;
        if (allFilesTitles.indexOf(name) === -1) {
          allFilsList.push({
            createdDt: file.createdDt,
            name: file.name,
            size: file.size,
          });
        }
      });

      setAllFiles(allFilsList);
    }
  }, [filesS3]);

  const filesHtml =
    filesS3?.length &&
    filesS3.map((f) => (
      <div className="fileStatus" key={f.fileId}>
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell small-5">
            <img className="link-image" alt="File Icon" src={FileIcon} />
            <span className={errorFiles.includes(f.name) ? "error-file" : null}>{f.name}</span>
          </div>
          <div className="cell small-1">{formatBytes(f.size)}</div>
          <div>{`${f.percent} %`}</div>
          <div className="cell small-3">
            <Tooltip
              title={
                f.speed
                  ? `Uploading file at ${f.speed} Kbps `
                  : "Calculating speed."
              }
              placement="top"
            >
              <LinearProgress variant="determinate" value={f.percent} />
            </Tooltip>
          </div>
          <div data-testid={`${f.name}-remove`} onClick={() => remove(f)}>
            <img className="link-image" alt="Cancel Icon" src={CancelIcon} />
          </div>
        </div>
      </div>
    ));

  const uploadComplete =
    (!_.isEmpty(filesS3) &&
      _.every(filesS3, function (file) {
        return file.percent === 100;
      })) ||
    false;

  useEffect(() => {
    if (uploadComplete) {
      setDisableUpload(false);
      const selectedTitles = selected.map((f) => f.name);
      const selectedList = [...selected];

      filesS3.forEach((file) => {
        const { name } = file;

        if (selectedTitles.indexOf(name) === -1) {
          selectedList.push({
            createdDt: file.createdDt,
            name: file.name,
            size: file.size,
          });
        }

        dispatchEvent({
          type: ExperimentAddActions.SET_ALL_FILES_PUSH,
          payload: file,
        });

        dispatchEvent({
          type: ExperimentAddActions.SET_UPLOADED_FILES,
          payload: name,
        });
      });

      if (disable && selectedTitles.length === selectedList.length) {
        setDisable(true);
      } else {
        setDisable(false);
      }
      dispatchEvent({
        type: ExperimentAddActions.SET_SELECTED,
        payload: selectedList,
      });

      dispatchAction({
        type: experimentActions.FILE_UPLOAD_COMPLETED,
        experimentId,
        payload: true,
      });

      dispatchAction({
        type: ExperimentAddActions.SET_DISABLE,
        payload: false,
      });
      return;
    }
    setDisableUpload(true);
  }, [uploadComplete]);

  const callOutAlert = state.error && (
    <div className="callout alert">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <WarningIcon />
        </div>
        <div className="cell auto">{state.error}</div>
      </div>
    </div>
  );

  const callOutSuccess = uploadComplete && !state.error && isMutatingSaveExp === 0 && (
    <div className="callout success">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <DoneIcon />
        </div>
        <div className="cell auto">
          <FormattedMessage
            id="experiment.add.uploadSuccess"
            values={{ num: _.size(filesS3) }}
          />
        </div>
      </div>
    </div>
  );

  const callOutToaster = fileExists && (
    <Toaster text="file.exist" autoHideDuration={15000} type={"error"} />
  );

  const callOutSaving = isMutatingSaveExp !== 0 && (

    <div className="callout primary">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <CircularProgress />
        </div>
        <div className="cell auto">
          <FormattedMessage
            id="experiment.save.in.progress"
          />
        </div>
      </div>
    </div>
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input data-testid="dropzone" {...getInputProps()} />
        {!_.isEmpty(filesS3) && (
          <div className="callout secondary">
            <ul>{filesHtml}</ul>
          </div>
        )}
        <div onClick={open} style={{ marginTop: filesHtml ? "0px" : "65px" }}>
          <span style={{ cursor: "pointer" }}>
            Click or drop your FastQ files here to upload.
          </span>
          <CloudUploadOutlinedIcon
            style={{
              color: "#green",
              height: 20,
              width: 20,
              marginLeft: 10,
              marginTop: 10,
              verticalAlign: "bottom",
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      {callOutAlert}
      {callOutSuccess}
      {callOutToaster}
      {callOutSaving}
    </section>
  );
}

const stateToProps = (state) => ({
  filesS3: state.fileReducer.filesS3,
  filesS3SignedUploaded: state.fileReducer.filesS3SignedUploaded,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, experimentActions), dispatch),
});

export default compose(connect(stateToProps, mapDispatchToProps))(UploadFile);
