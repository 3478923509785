import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "scss/App.scss";
import StylesProvider from "@mui/styles/StylesProvider";
import { Grid, Button } from "@mui/material";
import TopNav from "components/TopNav";
import SideNav from "components/SideNav";
import CompleteImage from "images/se-quoia-complete-visual.svg";
import ExpressImage from "images/express-rna-seq-visual.svg";
import useFetchPipelines from "components/Hooks/Pipelines/useFetchPipelines";
import useFetchExperimentDetailById from "components/Hooks/Experiment/useFetchExperimentDetailById";
import {
  COMPLETE_TEXT,
  EXPRESS_TEXT,
} from "components/common/Constants/pipelineData";
import { Form } from "components/common/ReactHookForm/Form";
import { Input } from "components/common/ReactHookForm/Input";
import { InputLabel } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetchRecentExperiments from "components/Hooks/Experiment/useFetchRecentExperiment";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import { useExperimentFileData } from "components/Hooks/Experiment/useExperimentFileData";
const schema = yup.object().shape({
  experimentName: yup
    .string()
    .trim()
    .min(5, "The experiment title must contain a minimum of 5 characters."),
  pipelineName: yup.string().trim().required("Pipeline Name is required"),
});

function CreateOrEditExperiments(props) {
  const { mode } = props;
  const experimentState = useExperimentsContext();
  const dispatch = useExperimentsDispatchContext();
  const {
    duplicateExperimentName,
    disable,
    disablePipeline,
    formStep,
    experimentTitle,
    experimentId,
    pipelineKit,
    cancelImage,
    pipelineKitImage,
    pipelineKitText,
  } = experimentState;

  const { data } = useFetchPipelines();
  const resolver = yupResolver(schema);
  const experimentsListData = useFetchRecentExperiments();
  const experimentData =
    mode === "edit-experiment"
      ? useFetchExperimentDetailById(experimentId, 0)
      : null;

  useExperimentFileData(experimentData);

  useEffect(() => {
    // add early return. Its only single logcal path this way
    if (!mode) {
      return;
    }
    if (experimentData?.data?.data?.experimentMeta?.pipelineName) {
      dispatch({
        type: actions.SET_PIPELINE_KIT,
        payload: experimentData.data.data.experimentMeta.pipelineName,
      });

      handleSelectKit({
        target: {
          name: "pipelineName",
          value: experimentData.data.data.experimentMeta.pipelineName,
        },
      });
      dispatch({
        type: actions.SET_DISABLE_PIPELINE,
        payload: false,
      });
      dispatch({
        type: actions.SET_DISABLE,
        payload: false,
      });
    }

    if (experimentData?.data?.data?.experimentMeta?.id) {
      dispatch({
        type: actions.SET_PIPELINE_ID,
        payload: experimentData.data.data.experimentMeta.id,
      });
    }
    if (experimentData?.data?.data?.experimentMeta?.experimentName) {
      dispatch({
        type: actions.SET_EXPERIMENT_TITLE,
        payload: experimentData.data.data.experimentMeta.experimentName,
      });

      setValue(
        "experimentName",
        experimentData.data.data.experimentMeta.experimentName
      );
    }
  }, [experimentData?.data?.data?.experimentMeta]);

  function handleSelectKit(event) {
    const pipelineDetail = data?.data?.find(
      (obj) => obj.pipelineName == event.target.value
    );
    dispatch({
      type: actions.SET_PIPELINE_ID,
      payload: pipelineDetail?.id,
    });
    dispatch({
      type: actions.SET_PIPELINE_KIT,
      payload: event.target.value,
    });
    dispatch({
      type: actions.SET_DISABLE_PIPELINE,
      payload: !event.target.value,
    });

    switch (event.target.value) {
      case "SEQuoia Complete":
        dispatch({
          type: actions.SET_PIPELINE_KIT_TEXT,
          payload: COMPLETE_TEXT,
        });
        dispatch({
          type: actions.SET_PIPELINE_KIT_IMAGE,
          payload: CompleteImage,
        });
        break;
      case "SEQuoia Express":
        dispatch({
          type: actions.SET_PIPELINE_KIT_TEXT,
          payload: EXPRESS_TEXT,
        });
        dispatch({
          type: actions.SET_PIPELINE_KIT_IMAGE,
          payload: ExpressImage,
        });
        break;
      default:
        break;
    }
  }

  const { register, errors, setValue } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    resolver,
  });

  const duplicateExpNameCheck = (value) => {
    dispatch({
      type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
      payload: "",
    });
    experimentsListData.isSuccess &&
      experimentsListData?.data?.data?.map((dupName) => {
        if (
          value === dupName.experimentName &&
          experimentId !== dupName.experimentId
        ) {
          dispatch({
            type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
            payload:
              "Experiment Name is already taken, please use a different Experiment Name",
          });
        }
      });
  };

  return (
    <StylesProvider injectFirst>
      <div id="wrapper" style={{ display: formStep >= 1 ? "none" : "block" }}>
        <TopNav />
        <div className="backdrop">
          <SideNav step="120vh" page="Experiment" />
          <Box className="content create-experiment-content">
            <Form className="content">
              <Box>
                <h1 className="medium-header">CREATE NEW EXPERIMENT</h1>
                <Grid container>
                  <Grid item xs={4}>
                    <Box
                      style={{ marginLeft: "32px" }}
                      className="settings-field"
                    >
                      <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel className="experiment-title">
                          EXPERIMENT TITLE
                        </InputLabel>
                        <Input
                          ref={register}
                          name="experimentName"
                          className="experiment-field"
                          size="small"
                          inputProps={{ "data-testid": "content-input" }}
                          placeholder={
                            experimentTitle === "" ? "My New Experiment" : ""
                          }
                          InputLabelProps={{ shrink: false }}
                          variant="outlined"
                          defaultValue={experimentTitle}
                          error={
                            duplicateExperimentName
                              ? duplicateExperimentName
                              : !!errors.experimentName
                          }
                          helperText={
                            duplicateExperimentName
                              ? duplicateExperimentName
                              : errors?.experimentName?.message
                          }
                          onChange={(text) => {
                            duplicateExpNameCheck(text.target.value);
                            dispatch({
                              type: actions.SET_DISABLE,
                              payload:
                                (!text.target.value ||
                                  !!errors.experimentName) &&
                                !text.target.value.length >= 5,
                            });
                            dispatch({
                              type: actions.SET_EXPERIMENT_TITLE,
                              payload: text.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="settings-field">
                      <FormControl className="select-sample" fullWidth={true}>
                        <Select
                          ref={register}
                          name="pipelineName"
                          variant="outlined"
                          data-testid="content-dropdown"
                          size="small"
                          value={pipelineKit}
                          onChange={handleSelectKit}
                          disabled={duplicateExperimentName !== ""}
                        >
                          <MenuItem style={{ display: "none" }} value="">
                            <em>-</em>
                          </MenuItem>
                          {data?.data.map((option, i) => {
                            return (
                              <MenuItem
                                disabled={option?.imageUrl === null}
                                key={i}
                                value={option?.pipelineName}
                              >
                                {option?.imageUrl !== null &&
                                  option?.pipelineName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          Select a Sample Prep Kit for this experiment
                          (Required).
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="content sample-kit-image">
                  <img alt="" src={pipelineKitImage} />
                </Box>
                <Box className="sample-kit-text-box">
                  <p className="sample-kit-text">{pipelineKitText}</p>
                </Box>
                <Box className="footer">
                  <Box className="footer-content">
                    <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      className="blue-link"
                      onMouseOver={() => {
                        dispatch({
                          type: actions.SET_CANCEL_IMAGE,
                          payload: "CancelIconHover",
                        });
                      }}
                      onMouseOut={() => {
                        dispatch({
                          type: actions.SET_CANCEL_IMAGE,
                          payload: "CancelIcon",
                        });
                      }}
                    >
                      <img
                        className="link-image"
                        alt="Cancel"
                        src={cancelImage}
                      />
                      Cancel
                    </Link>{" "}
                  </Box>
                  <Box
                    style={{ position: "absolute", right: "240px" }}
                    className="footer-content"
                  >
                    <Button
                      onClick={() => {
                        if (duplicateExperimentName === "")
                          dispatch({
                            type: "SET_FORM_STEP",
                            payload: (cur) => cur + 1,
                          });
                          window.history.pushState(
                            "Seqsense-2.0",
                            "Experiment Details",
                            experimentData?.data?.data?.experimentMeta.experimentId !== undefined ? `/create-experiment/file-selection/${experimentData?.data?.data?.experimentMeta.experimentId}` : `/create-experiment/file-selection`
                          );
                      }}
                      className="blue-button"
                      disabled={
                        disable ||
                        disablePipeline ||
                        duplicateExperimentName !== "" ||
                        !!errors.experimentName
                      }
                    >
                      NEXT: ADD SAMPLE FILES
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          </Box>
        </div>
      </div>
    </StylesProvider>
  );
}

export default CreateOrEditExperiments;
