import React, { useState, useEffect } from 'react';
import 'scss/App.scss';
import { Box, Paper, CircularProgress } from '@mui/material';


function HTMLReports({ value, index, values }) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (values?.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, values)

    return (
        <div>
            {value === index &&
                <Paper style={{ display: "flex", height: '100%' }}>
                    <Box id="html-report">
                        <p className="large-text">HTML Reports for each sample file will open in a new browser tab</p>
                        {values ? values?.map((htmlReports, index) => (
                            <Box key={index} className="report-group">
                                <Box className="report">
                                    <a style={{ wordBreak: "break-all" }} className="blue-link" target="_blank" href={htmlReports.preSignedUrl} rel="noreferrer" ><p style={{ fontSize: "14px" }}>{htmlReports.file.substring(htmlReports.file.lastIndexOf('/') + 1)}</p></a>
                                </Box>
                            </Box>
                        )) : loading ? <Box className="progress-label">
                            <CircularProgress color="success" />
                        </Box> : (
                            <div className="no-reports">
                                No Reports Available
                            </div>
                        )
                        }
                    </Box>
                </Paper>
            }
        </div>
    );
}

export default HTMLReports;
