import React from "react";
import { render } from "react-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { MSAL_CONFIG } from "./app.config";
import { AppContainer } from "react-hot-loader";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
const store = configureStore();

const msalInstance = new PublicClientApplication(MSAL_CONFIG);
// Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   //console.log(msalInstance.getAllAccounts());
//   //msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
    const account = event.payload.account;
    // console.log(`Logged in as ${account.username}`);
    msalInstance.setActiveAccount(account);
    // console.log("account in callback", account);
  }
});

render(
  <AppContainer>
    <MsalProvider instance={msalInstance}>
      <Root store={store} history={history} />
    </MsalProvider>
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <MsalProvider instance={msalInstance}>
          <NewRoot store={store} history={history} />

        </MsalProvider>
      </AppContainer>,
      document.getElementById("app")
    );
  });
}