import React, { useEffect, useState } from 'react';
import 'scss/App.scss';
import { Button, Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import Home from '../images/icons-main-nav-home.svg';
import HomeHover from '../images/icons-main-nav-home-hover.svg';
import HomeActive from '../images/icons-main-nav-home-selected.svg';
import Experiment from '../images/icons-main-nav-experiment.svg'
import ExperimentHover from '../images/icons-main-nav-experiment-hover.svg';
import ExperimentActive from '../images/icons-main-nav-experiment-selected.svg';
import Tags from '../images/icons-main-nav-tags.svg';
import TagsHover from '../images/icons-main-nav-tags-hover.svg';
import TagsActive from '../images/icons-main-nav-tags-selected.svg';
import Samples from '../images/icons-main-nav-samples.svg';
import SamplesHover from '../images/icons-main-nav-samples-hover.svg';
import SamplesActive from '../images/icons-main-nav-samples-selected.svg';
import Reference from '../images/icons-main-nav-ref.svg';
import ReferenceHover from '../images/icons-main-nav-ref-hover.svg';
import ReferenceActive from '../images/icons-main-nav-ref-selected.svg';
import Expand from '../images/icons-main-nav-expand.svg';
import ExpandHover from '../images/icons-main-nav-expand-hover.svg';
import Collapse from '../images/icons-main-nav-collapse.svg';
import CollapseHover from '../images/icons-main-nav-collapse-hover.svg';


function SideNav(props) {

    const style = {
        fontSize: '9px',
        fontFamily: 'Rubik',
        fontWeight: 400,
        borderRadius: 0,
        width: '95%',
        paddingBottom: '2px',
        backgroundColor: 'white',
        justifyContent: 'flex-start',
        textAlign: 'left',
        minWidth: '0px',
    }

    const activeStyle = {
        fontSize: '9px',
        fontFamily: 'Rubik',
        fontWeight: 400,
        borderRadius: 0,
        width: '95%',
        paddingBottom: '2px',
        backgroundColor: 'white',
        color: '#00a650',
        justifyContent: 'flex-start',
        textAlign: 'left',
        minWidth: '0px',
        borderBottom: '',
    }

    const [homeImage, setHomeImage] = useState(Home);
    const [experimentImage, setExperimentImage] = useState(Experiment);
    const [tagsImage, setTagsImage] = useState(Tags);
    const [samplesImage, setSamplesImage] = useState(Samples);
    const [referenceImage, setReferenceImage] = useState(Reference);
    const [activeLink, selectNavLink] = useState("Home");
    const [show, setShow] = useState(true);

    useEffect(() => {
        selectNavLink(props.page);
    }, [props.page])


    let button;
    let navClass;
    if (show) {
        button = <Box><a className='collapse-link' onClick={() => setShow(false)}><img src={Collapse} alt="Collapse" onMouseOver={e => (e.currentTarget.src = CollapseHover)} onMouseOut={e => (e.currentTarget.src = Collapse)} /></a></Box>;
        navClass = 'side-link';
    } else {
        button = <Box><a className='expand-link' onClick={() => setShow(true)}><img src={Expand} alt="Expand" onMouseOver={e => (e.currentTarget.src = ExpandHover)} onMouseOut={e => (e.currentTarget.src = Expand)} /></a></Box>;
        navClass = 'side-link-collapsed';
    }


    return (
        <div className={show ? 'side-nav' : 'side-nav-collapsed'}>
            <Box className={navClass} onClick={() => { selectNavLink('Home') }} onMouseOver={() => setHomeImage(HomeHover)} onMouseOut={() => setHomeImage(activeLink === "Home" ? HomeActive : Home)}>
                <Tooltip title='Home'>
                    <Link to='/home' style={{ textDecoration: 'none' }}><Button className={activeLink === 'Home' ? 'side-nav-button side-nav-button-active' : 'side-nav-button'} style={activeLink === 'Home' ? activeStyle : style}><img className='side-nav-img' src={homeImage} alt='Home' /> {show ? "HOME" : ""}</Button></Link>
                </Tooltip>
            </Box>
            <Box className={navClass} onClick={() => { selectNavLink('Experiments') }} onMouseOver={() => setExperimentImage(ExperimentHover)} onMouseOut={() => setExperimentImage(activeLink === "Experiments" ? ExperimentActive : Experiment)}>
                <Tooltip title='Experiments'>
                    <Link to='/experiments' style={{ textDecoration: 'none' }}><Button className={activeLink === 'Experiment' ? 'side-nav-button side-nav-button-active' : 'side-nav-button'} style={activeLink === 'Experiments' ? activeStyle : style}><img className='side-nav-img' src={experimentImage} alt='Experiments' /> {show ? "EXPERIMENTS" : ""}</Button></Link>
                </Tooltip>
            </Box>
            <Box className={navClass} onClick={() => { selectNavLink('Samples') }} onMouseOver={() => setSamplesImage(SamplesHover)} onMouseOut={() => setSamplesImage(activeLink === "Samples" ? SamplesActive : Samples)}>
                <Tooltip title='Samples'>
                    <Link to='/samples' style={{ textDecoration: 'none' }}><Button className={activeLink === 'Samples' ? 'side-nav-button side-nav-button-active' : 'side-nav-button'} style={activeLink === 'Samples' ? activeStyle : style}><img className='side-nav-img' src={samplesImage} alt='Samples' />{show ? "SAMPLES" : ""}</Button></Link>
                </Tooltip>
            </Box>
            <Box className={navClass} onClick={() => { selectNavLink('Tags') }} onMouseOver={() => setTagsImage(TagsHover)} onMouseOut={() => setTagsImage(activeLink === "Tags" ? TagsActive : Tags)}>
                <Tooltip title='Tags'>
                    <Link to='/tag/list' style={{ textDecoration: 'none' }}><Button className={activeLink === 'Tags' ? 'side-nav-button side-nav-button-active' : 'side-nav-button'} style={activeLink === 'Tags' ? activeStyle : style}><img className='side-nav-img' src={tagsImage} alt='Tags' />{show ? "TAGS" : ""}</Button></Link>
                </Tooltip>
            </Box>
            <Box className={navClass} onClick={() => { selectNavLink('Reference') }} onMouseOver={() => setReferenceImage(ReferenceHover)} onMouseOut={() => setReferenceImage(activeLink === "Reference" ? ReferenceActive : Reference)}>
                <Tooltip title='Help & Support'>
                    <a href='https://www.bio-rad.com/SeqSenseFAQs' rel="noopener noreferrer" target='_blank' className={activeLink === 'Reference' ? 'side-nav-button side-nav-button-active' : 'side-nav-button'} style={activeLink === 'Reference' ? activeStyle : style}><img className='side-nav-img' src={referenceImage} alt='Reference' /> {show ? "HELP & SUPPORT" : ""}</a>
                </Tooltip>
            </Box>
            {button}
        </div>
    );
}

export default SideNav;
