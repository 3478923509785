import React, { useReducer } from 'react';
import { Box, Chip, Paper, Modal, LinearProgress, Tooltip, FormControl, Button } from "@mui/material";
import TopNav from "../../TopNav";
import SideNav from "../../SideNav";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import StylesProvider from '@mui/styles/StylesProvider';
import BRShapes from "../../../images/B-Rshapes.png";
import useFetchAllSampleFiles from "../../Hooks/SampleFiles/useFetchAllSampleFiles";
import { TableOptions } from '../../common/Utils/DataTableOptions';
import DataTable from '../../common/DataTable/DataTable';
import CancelIcon from '../../../images/icons-close-x-icon-small.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import useDeleteSampleFiles from '../../Hooks/SampleFiles/useDeleteSampleFiles';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DateFormatter from '../../common/Utils/DateFormatter';
import { Form } from '../../common/ReactHookForm/Form';
import { Input } from "../../common/ReactHookForm/Input";
import { useForm } from "react-hook-form";
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import useMutateSampleTagAdd from '../../Hooks/Tags/useMutateSampleTagAdd';
import useMutateSampleTagDelete from '../../Hooks/Tags/useMutateSampleTagDelete';
import { useDispatch } from 'react-redux';
import { USER_MESSAGE } from '../../../actions/User';
import { MAXTAGLIMIT } from "../../common/Constants/appConstants";
import _ from "underscore";

const schema = yup.object().shape({
    tagName: yup.string().trim().max(20, "The tag name cannot be more than 20 characters.").required(),
});

const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
});

const initState = {
    loading: false,
    error: false,
    open: false,
    fileName: '',
    pipelineName: '',
    openTag: false,
    fileId: ''
};

const SampleFiles = () => {
    const list = useFetchAllSampleFiles();
    const [state, setState] = useReducer(reducer, initState);
    const resolver = yupResolver(schema);
    const allSamples = list?.data && list?.data.data || [];
    const deleteSample = useDeleteSampleFiles();
    const addSampleTag = useMutateSampleTagAdd();
    const tagSampleDelete = useMutateSampleTagDelete();
    const dispatch = useDispatch();

    allSamples.length && allSamples.map((key, index) => {
        key.tagSearch = key.tags && _.pluck(key.tags, "tagName").join(" ") || ""
        key.tags = key.tags
        key.id = index //Need to add an id for the MUI Datagrid to work
        key.createdAt = DateFormatter(key.createdAt);
    });

    const { register, handleSubmit, errors, formState: { isSubmitting, isDirty, isValid } } = useForm({
        criteriaMode: "all",
        mode: "onChange",
        resolver
    });
    
    const gridData = {
        columns: [
            {
                name: "userExpFileId",
                label: '',
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                }
            },
            {
                name: "fileName",
                label: 'Name',
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={`${value}`} placement="top">
                                <div style={{ color: '#00a650' }}>{value}</div>
                            </Tooltip>
                        )
                    }
                }
            },
            {
                name: "pipelineName",
                label: 'Prep Kit Type',
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "createdAt",
                label: 'Created',
                options: {
                    filter: true,
                    sortCompare: (order) => {
                        return (a, b) => {
                          let x = Date.parse(a.data);
                          let y = Date.parse(b.data);
                          return (x - y) * (order === 'asc' ? 1 : -1);
                        };
                    }
                }
            },
            {
                name: "tags",
                label: 'Tags',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (tags, tableMeta) => {
                        return (
                            <div>
                                {tags?.slice(0,5).map((tag) =>
                                    <Chip
                                        key={tag?.id}
                                        label={tag?.tagName?.toUpperCase()}
                                        disabled={tagSampleDelete.status === 'loading'}
                                        onDelete={() => handleDelete(tag,tableMeta.rowData[0])}
                                        deleteIcon={<img src={CancelIcon} id={tag?.id} name={tag?.tagName} style={{ height: "12px", width: "12px" }} />}
                                    />
                                )}
                                <AddIcon style={{ color: '#0064b0', cursor: 'pointer', verticalAlign: 'middle' }} onClick={() => handleTagOpen(tableMeta.rowData)} />
                            </div>
                        )
                    }
                }
            },
            {
                name: '',
                label: '',
                options: {
                    filter: false,
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta) => {
                        return <DeleteIcon style={{ color: '#0064b0', cursor: 'pointer' }} onClick={() => handleOpen(tableMeta.rowData[0])} />;
                    }
                }
            },
            {
                name: "tagSearch",
                label: 'Tags',
                options: {
                    filter: true,
                    display: true,
                    sort: false,
                    viewColumns: true,
                    customBodyRender: (tagSearch, tableMeta) => {
                        return (
                            <div style={{ display: 'none' }}>
                                {tagSearch}
                            </div>
                        )
                    },
                    customHeadRender: () => null
                }
            },
        ],
        options: TableOptions.options,
        data: list.data && list.data.data || [],
        themeOptions: TableOptions.themeOptions
    }

    const newTagName = (e) => {
        setState({ tagName: e.currentTarget.value });
    }

    const onSubmit = (data, e) => {
        const fileName = state.fileName;
        const pipelineName = state.pipelineName;
        const tagName = data.tagName;
        const values = { fileName, pipelineName, tagName };
        addSampleTag.mutate(values)
        handleClose();
    }

    const handleTagOpen = (tableData) => {
        gridData.options.sortOrder = JSON.parse(localStorage.getItem('sortOrder')) || gridData.options.sortOrder;
        if (tableData[4].length > MAXTAGLIMIT) {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "tag.max",
                key: Math.random()
            });
        }
        else {
            setState({
                openTag: true,
                pipelineName: tableData[2],
                fileName: tableData[1],
                fileId: tableData[0],
            })
        }
    }

    const handleOpen = (id) => {
        gridData.options.rowsPerPage = parseInt(localStorage.getItem('rowsPerPage')) || gridData.options.rowsPerPage;
        gridData.options.sortOrder = JSON.parse(localStorage.getItem('sortOrder')) || gridData.options.sortOrder;
        setState({
            open: true,
            fileId: id
        })
    };
    const handleClose = () => { setState({ open: false }); setState({ openTag: false }) }

    const handleDelete = (data, fileId) => {
        gridData.options.sortOrder = JSON.parse(localStorage.getItem('sortOrder')) || gridData.options.sortOrder;
        const variables = {
            fileId: fileId,
            ...data
        };
        if (data) {
            tagSampleDelete.mutate(variables)
        }
    };

    const deleteSelectedSample = () => {
        deleteSample.mutate(state.fileId);
    }

    return (
        <StylesProvider injectFirst>
            <div id='wrapper'>
                <TopNav />
                <div className='home-backdrop'>
                    <SideNav step="130vh" page="Samples" />
                    <Box style={{ backgroundImage: `url("${BRShapes}")`, display: 'flex', flexDirection: 'column', padding: '1% 2%' }} id="home-wrapper">
                        <Box style={{ padding: '0 1% 3% 2%' }}>
                            <h3>Samples</h3>
                        </Box>
                        <Box id="recent-experiments-grid" style={{ height: '600px', width: '100%', alignSelf: "center" }}>
                            <Box style={{ height: '100%', width: '100%' }}>
                                {(addSampleTag.isLoading || tagSampleDelete.isLoading) && <LinearProgress color="success" />}
                                {list.isSuccess ? <DataTable id={1} props={gridData} /> : list.isFetching ? <LinearProgress color="success" /> : <DataTable id={1} props={gridData} />}
                            </Box>
                        </Box>
                        <Modal open={state.openTag} style={{ display: 'flex' }}>
                            <Paper id="addtag-modal">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Box>
                                        <p className="eula-header">Add Tag</p>
                                    </Box>
                                    <Box className="top-border small-padding-top">
                                        <FormControl variant='outlined' fullWidth={true}>
                                            <Input
                                                ref={register}
                                                name='tagName'
                                                id="addtag-field"
                                                variant='outlined'
                                                label='Tag Name'
                                                onChange={newTagName}
                                                error={!!errors.tagName}
                                                helperText={errors && errors.tagName && errors.tagName.message ? errors.tagName.message : 'Max. 20 Characters'}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box id="addtag-footer">
                                        <Button style={{ cursor: 'pointer' }} className="blue-link medium-text link2" onClick={handleClose}>Cancel</Button>
                                        <Button variant="text" style={{ cursor: 'pointer' }} className="blue-link medium-text" disabled={!isDirty || !isValid} name="submit" type="submit">OK</Button>
                                    </Box>
                                </Form>
                            </Paper>
                        </Modal>
                        <Modal open={state.open}>
                            <Paper className="" id="delete-experiment-modal">
                                <Box id="delete-header-box" >
                                    <ErrorOutlineOutlinedIcon className="delete-header delete-icon" style={{ fontSize: '48px' }} />
                                    <h3 className="info-title delete-header" style={{ marginLeft: '5px' }}>Delete Sample?</h3>
                                </Box>
                                <Box style={{ paddingLeft: '10px' }}>
                                    <p>You will not be able to recover it.</p>
                                </Box>
                                <Box id="addtag-footer">
                                    <a href="#" className="blue-link medium-text link2" onClick={handleClose}>CANCEL</a>
                                    <a href="#" className="blue-link medium-text" onClick={deleteSelectedSample}>DELETE</a>
                                </Box>
                            </Paper>
                        </Modal>
                    </Box>
                </div>
            </div>
        </StylesProvider>
    );
}

export default SampleFiles;
