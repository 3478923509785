import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from "react-query";

const copyExistingFilesFromAWS = async (values) => {
  const {
    experimentId,
    fileNameList,
    pipelineId,
    fileId,
    onSuccess,
    onError,
    addCancelProgressBarToken,
  } = values;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  addCancelProgressBarToken(source);

  const postData = {
    experimentId: experimentId,
    fileList: fileNameList,
    pipelineId: pipelineId,
    fileId: fileId
  };
  const headerConfig = setHeaders();
  const copyExistingFilesFromAWSUrl = `${config.ENDPOINTS["main"]}/files/copy`;

  return await axios
    .post(copyExistingFilesFromAWSUrl, postData, {
      headers: headerConfig,
      cancelToken: source.token,
    })
    .then(onSuccess)
    .catch(onError);
};

export default function useMutateCopyExistingFilesFromAWS() {
  return useMutation(copyExistingFilesFromAWS);
}
