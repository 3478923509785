import * as actions from "actions/ExperimentAdd";
import LeftArrow from "images/circle-arrow-left.svg";
import LeftArrowHover from "images/circle-arrow-left-hover.svg";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import CancelIconHover from "images/icons-close-x-icon-black-small-hover.svg";
import AddIcon from "images/add-circle-big.svg";
import AddIconHover from "images/add-circle-big-hover.svg";
import AddIconDisabled from "images/icons-add-circle-dim.svg";
import ExpandBlue from "images/icons-expand-icon-blue.svg";
import ExpandBlueHover from "images/icons-expand-icon-blue-hover.svg";
import CollapseBlue from "images/icons-collapse-icon-blue.svg";
import CollapseBlueHover from "images/icons-collapse-icon-blue-hover.svg";
import getOriginalFileName from "../../components/common/Utils/FileUtil";

export const defaultAnalysisData = {
  minMapqToCount: 1,
  minBp: 15,
  threePrimeQualCutoff: 0,
  fivePrimeQualCutoff: 0,
  skipUmi: "false",
  noTrim: "false",
  genome: "hg38",
  spikeType: "NONE",
  seqType: "PE",
  minGeneType: "None",
  minGeneCutoff: "0",
};

export const expermentAddInitialState = {
  fileExists: false,
  addTagDisabled: true,
  allFiles: [],
  allTags: [],
  arrowImage: LeftArrow,
  cancelImage: CancelIcon,
  disable: true,
  disablePipeline: true,
  disableUpload: true,
  duplicateExperimentName: "",
  duplicateTag: false,
  experimentTitle: "",
  formStep: 0,
  newTags: [],
  open: false,
  openAddedTags: false,
  pipelineId: "",
  pipelineKit: "",
  pipelineKitImage: "",
  pipelineKitText: "",
  pollingStatus: false,
  addedTagsLength: 0,
  tabValue: "upload",
  defaultAnalysis: defaultAnalysisData,
  addImage: AddIconDisabled,
  experimentId: "",
  fileNameError: false,
  collapseImage: CollapseBlue,
  expandImage: ExpandBlue,
  expand: true,
  order: "asc",
  orderBy: "createdDt",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  uploadFiles: [],
  showError: false,
  cancelEvents: {},
  retry: [],
  existingDialog: { open: false, filenames: [] }
};

export function experimentAddReducer(draft, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_EXISTING_DUPLICATE_ERROR_FILE_DIALOG: {
      draft.existingDialog = payload;
      return draft;
    }
    case actions.SET_FILE_EXISTS: {
      draft.fileExists = payload;
      return draft;
    }
    case actions.SET_CANCEL_EVENTS: {
      draft.cancelEvents = payload;
      return draft;
    }
    case actions.SET_SHOW_ERROR: {
      draft.showError = payload;
      return draft;
    }
    case actions.SET_RETRY: {
      if (payload) {
        draft.retry.push(payload);
      }
      return draft;
    }
    case actions.SET_FILTER_RETRY: {
      if (payload) {
        draft.retry = draft.retry.filter((file) => file !== payload);
      }
      return draft;
    }
    case actions.SET_UPLOADED_FILES: {
      if (payload) {
        const found = draft.uploadFiles.find((f) => f === payload);
        if (!found) {
          draft.uploadFiles.push(payload);
        }
      }
      return draft;
    }

    case actions.REPLACE_UPLOADED_FILES: {
      draft.uploadFiles = payload;
      return draft;
    }


    case actions.SET_FILTER_SELECTED: {
      if (payload) {
        draft.selected = draft.selected.filter((file) => file.fileId !== payload);
      }
      return draft;
    }
    case actions.SET_FILTER_ALL_FILES: {
      if (payload) {
        draft.allFiles = draft.allFiles.filter((file) => file.fileId !== payload);
      }
      return draft;
    }

    case actions.SET_FILTER_ALL_FILES_BY_NAME: {
      if (payload) {
        let fileName = getOriginalFileName(payload);
        draft.allFiles = draft.allFiles.filter((file) => !file.name.startsWith(fileName));
      }
      return draft;
    }

    case actions.SET_FILTER_UPLOADED_FILES: {
      if (payload) {
        draft.uploadFiles = draft.uploadFiles.filter(
          (file) => file !== payload
        );
      }
      return draft;
    }
    case actions.SET_PAGE: {
      draft.page = payload;
      return draft;
    }
    case actions.SET_ROWS_PER_PAGE: {
      draft.rowsPerPage = payload;
      return draft;
    }
    case actions.SET_ORDER: {
      draft.order = payload;
      return draft;
    }
    case actions.SET_ORDER_BY: {
      draft.orderBy = payload;
      return draft;
    }
    case actions.SET_SELECTED: {
      draft.selected = payload;
      return draft;
    }
    case actions.SET_EXPAND: {
      draft.expand = payload;
      return draft;
    }
    case actions.SET_EXPAND_IMAGE: {
      draft.collapseImage =
        payload === "ExpandBlueHover" ? ExpandBlueHover : ExpandBlue;
      return draft;
    }
    case actions.SET_COLLAPSE_IMAGE: {
      draft.expandImage =
        payload === "CollapseBlueHover" ? CollapseBlueHover : CollapseBlue;
      return draft;
    }
    case actions.SET_DEFAULT_ANALYSIS: {
      draft.defaultAnalysis = payload;
      return draft;
    }
    case actions.SET_ADD_IMAGE: {
      if (payload === "AddIconDisabled") {
        draft.addImage = AddIconDisabled;
      } else if (payload === "AddIcon") {
        draft.addImage = AddIcon;
      } else if (payload == "AddIconHover") {
        draft.addImage = AddIconHover;
      }
      return draft;
    }

    case actions.SET_FILE_NAME_ERROR: {
      draft.fileNameError = payload;
      return draft;
    }

    case actions.SET_ADDED_TAGS_LENGTH: {
      draft.addedTagsLength = payload;
      return draft;
    }
    case actions.SET_EXPERIMENT_ID: {
      draft.experimentId = payload;
      return draft;
    }
    case actions.SET_TAB_VALUE: {
      draft.tabValue = payload;
      return;
    }
    case actions.SET_OPEN: {
      draft.open = payload;
      return draft;
    }
    case actions.SET_OPEN_ADDED_TAGS: {
      draft.openAddedTags = payload;
      return draft;
    }
    case actions.SET_DUPLICATE_TAG: {
      draft.duplicateTag = payload;
      return draft;
    }
    case actions.SET_DISABLE_UPLOAD: {
      draft.disableUpload = payload;
      return draft;
    }
    case actions.SET_ADD_TAG_DISABLED: {
      draft.addTagDisabled = payload;
      return draft;
    }
    case actions.SET_PIPELINE_KIT_TEXT: {
      draft.pipelineKitText = payload;
      return draft;
    }
    case actions.SET_PIPELINE_KIT_IMAGE: {
      draft.pipelineKitImage = payload;
      return draft;
    }
    case actions.SET_CANCEL_IMAGE: {
      draft.cancelImage =
        payload === "CancelIcon" ? CancelIcon : CancelIconHover;
      return draft;
    }
    case actions.SET_FORM_STEP: {
      draft.formStep = payload(draft.formStep);
      return draft;
    }
    case actions.SET_PIPELINE_KIT: {
      draft.pipelineKit = payload;
      return draft;
    }
    case actions.SET_PIPELINE_ID: {
      draft.pipelineId = payload;
      return draft;
    }
    case actions.SET_EXPERIMENT_TITLE: {
      draft.experimentTitle = payload;
      return draft;
    }

    case actions.SET_POLLING_STATUS: {
      draft.pollingStatus = payload;
      return draft;
    }
    case actions.SET_ARROW_IMAGE: {
      draft.arrowImage = payload === "LeftArrow" ? LeftArrow : LeftArrowHover;
      return draft;
    }
    case actions.SET_DISABLE: {
      draft.disable = payload;
      return draft;
    }
    case actions.SET_DISABLE_PIPELINE: {
      draft.disablePipeline = payload;
      return draft;
    }
    case actions.SET_DUPLICATE_EXPERIMENT_NAME: {
      draft.duplicateExperimentName = payload;
      return draft;
    }
    case actions.SET_ALL_FILES_PUSH: {
      if (payload) {
        const found = draft.allFiles.find((f) => f.fileId === payload.fileId);
        if (!found) {
          draft.allFiles.push(payload);
        }
      }
      return draft;
    }
    case actions.SET_ALL_FILES: {
      if (payload) {
        draft.allFiles = payload;
      }
      return draft;
    }
    case actions.SET_ALL_TAGS: {
      if (payload) {
        draft.allTags = payload;
      }
      return draft;
    }
    case actions.SET_NEW_TAGS: {
      if (payload) {
        draft.newTags = payload;
      }
      return draft;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}
