import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from 'react-query';

const saveExperiment = async (values) => {
    let allFilesArray = []
    let tagsArray = []

    values?.allFiles?.forEach((element) => {
        allFilesArray.push({fileName: element.name, fileSize: element.size})
    });

    values?.tags?.forEach((element) => {
        tagsArray.push({tagName: element})
    });

    const postData = {
        experimentId: values.experimentId,
        experimentName: values.experimentTitle,
        pipelineId: values.pipelineId,
        fileData: allFilesArray,
        notes: values.notes,
        tags: tagsArray
    };
    const headerConfig = setHeaders();
    let saveUrl = `${config.ENDPOINTS["main"]}/create-experiment`

    const { data } = await axios
        .post(saveUrl, postData, {
            headers: headerConfig
        })

    return data;
}

export default function useMutateSaveExperiment() {
    return useMutation(["saveExp"],saveExperiment, {
        onError: () => {
            //error handler
        }
    })
}