import React, { useState, useEffect } from 'react';
import 'scss/App.scss';
import { Box, Grid, Paper, FormControl, MenuItem, TextField, CircularProgress } from '@mui/material';
import Upload from "../images/icons-cloud-download.svg";


function OtherFiles({ value, values, index }) {

    const [sampleFile, setSampleFile] = useState('');
    const [uploadImage, setUploadImage] = useState(Upload);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (values?.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, values)

    const handleSampleFile = (e) => {
        setSampleFile(e.target.value);
    }

    return (
        <div>
            {value === index &&
                <Paper style={{ display: "flex" }}>
                    <Box id="html-report">
                        <Box style={{ marginTop: "20px" }}>
                            <FormControl required fullWidth={true}>
                                <TextField
                                    label="Select Sample File"
                                    select
                                    variant='outlined'
                                    size='small'
                                    value={sampleFile || Object.entries(values)[0][0]}
                                    onChange={handleSampleFile}>
                                    {Object.entries(values).map((option, i) => {
                                        return (
                                            <MenuItem key={i} value={option[0]}>
                                                {option[0] !== 'report' && option[0]}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box style={{ marginTop: "20px", borderTop: "solid 2px #dcdfe0" }}>
                            {Object.entries(values).map((option) => (
                                option[0] === sampleFile && (
                                    option[0] !== 'report' && Object.entries(option[1]).map((group, index) => (
                                        <Box key={index} className="report-group">
                                            <p>{group[0]}</p>
                                            {Object.entries(group[1]).map((file, index) => (
                                                <Grid key={index} container className="report">
                                                    <Grid item xs={9}>
                                                        <a style={{ wordBreak: "break-all" }} className="blue-link" target="blank" href={file[1].preSignedUrl}><p className="inline-text" style={{ fontSize: "14px" }}>{file[1]?.file?.split("/").pop()}</p></a>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <p className="inline-text">{Math.floor(Math.log2(file[1].size) / 10) + " MB"}</p>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <img src={uploadImage} alt="Download" onMouseOver={() => setUploadImage(Upload)} onMouseOut={() => setUploadImage(Upload)} />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    ))
                                )
                            ))}
                            {Object.entries(values).length ? Object.entries(values).map((option, outputIndex) => (
                                sampleFile === '' && (
                                    outputIndex === 0 && option[0] !== 'report' && Object.entries(option[1]).map((group, index) => (
                                        <Box key={index} className="report-group">
                                            <p>{group[0]}</p>
                                            {Object.entries(group[1]).map((file, index) => (
                                                <Grid key={index} container className="report">
                                                    <Grid item xs={9}>
                                                        <a style={{ wordBreak: "break-all" }} className="blue-link" target="blank" href={file[1].preSignedUrl}><p className="inline-text" style={{ fontSize: "14px" }}>{file[1]?.file?.split("/").pop()}</p></a>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <p className="inline-text">{Math.floor(Math.log2(file[1].size) / 10) + " MB"}</p>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <img src={uploadImage} alt="Download" onMouseOver={() => setUploadImage(Upload)} onMouseOut={() => setUploadImage(Upload)} />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    ))
                                )
                            )) : loading ? <Box style={{ marginTop: "150px" }} className="progress-label">
                                <CircularProgress color="success" />
                            </Box> : (
                                <div className="no-reports">
                                    No Files Available
                                </div>
                            )
                            }
                        </Box>
                    </Box>
                </Paper>
            }
        </div>
    );
}

export default OtherFiles;
